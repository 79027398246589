/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { Component } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { withStyles } from "@material-ui/core/styles"
import WebFont from "webfontloader"

import Header from "./Header"
import Hero from "./Hero"
import Footer from "./Footer"
import Animation from "./Animation"

import "./layout.css"
import "../styles//custom.scss"
import "../styles/adjustiment.scss"
import "../styles/animation.scss"

class Layout extends Component {
  componentDidMount() {
    WebFont.load({
      google: {
        families: ["Noto+Sans+JP", "Roboto"],
      },
    })
  }

  render() {
    const { classes, children } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <Animation>
            <div className={classes.container}>
              <div className={classes.root}>
                <div className={classes.background}>
                  <Header />
                  <Hero />
                </div>
                <main className={classes.main}>{children}</main>
                <Footer />
              </div>
            </div>
          </Animation>
        )}
      />
    )
  }
}

const styles = theme => ({
  container: {
    display: "flex",
    height: "100%",
    backgroundColor: "#F7F8F8",
  },
  main: {
    backgroundColor: "#F7F8F8",
  },
  root: {
    backgroundSize: "100%",
    position: "relative",
    minHeight: "100vh",
    paddingBottom: 120,
    boxSizing: "border-box",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 112,
    },
  },
  background: {
    backgroundColor: "#FFF",
    textAlign: "center",
    margin: "auto",
    maxHeight: 1080,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    //backgroundPosition: "50% 100%",
    [theme.breakpoints.down("xs")]: {
      height: 357,
    },
  },
})

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withStyles(styles)(Layout)
