import { Link, navigate } from "gatsby"

import React, { Component } from "react"
import { pure, compose } from "recompose"
import { withStyles } from "@material-ui/core/styles"
import withWidth from "@material-ui/core/withWidth"
import { scrollToTarget } from "../utils"
import classNames from "classnames"

import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/icons/Menu"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Divider from "@material-ui/core/Divider"

type Props = {
  classes: any
  width: string
}

type State = {
  openDrawer: boolean
  menuItems: Array<{ name: string; link: string }>
}

class Header extends Component<Props, State> {
  constructor(props: any) {
    super(props)

    const menuItems = [
      { name: "MESSAGE", link: "message" },
      { name: "SEVICE", link: "service" },
      { name: "TRAINERS", link: "trainers" },
      { name: "SECRET", link: "secret" },
      { name: "COMPANY", link: "company" },
      { name: "CONTACT", link: "contact" },
    ]

    this.state = {
      openDrawer: false,
      menuItems,
    }
  }

  _toggleDrawer = (open: boolean) => () => {
    this.setState({
      openDrawer: open,
    })
  }

  _handleOnClick = (target: string) => (event: any) => {
    if (target === "contact") {
      navigate(`/${target}`)
    } else if (document.location.pathname === "/") {
      scrollToTarget(target)
    } else {
      navigate(`/#${target}`)
    }
  }

  render() {
    const { classes } = this.props

    const menuListDrawer = (
      <List>
        <div className={classes.drawerTitleWrapper}>
          <div className={classes.title}>
            <div className={classes.titleText}>
              <img
                src="/assets/images/logo.jpg"
                alt="プラスメンタル"
                className={classes.image}
              />
            </div>
          </div>
        </div>
        <Divider />
        {this.state.menuItems.map((menuItem, i) => (
          <ListItem
            button
            key={`drawer-menu-${i}`}
            component={Link}
            to={
              menuItem.name === "CONTACT" ? menuItem.link : `#${menuItem.link}`
            }
          >
            <ListItemText
              primary={menuItem.name}
              className={classes.menuLink}
            />
          </ListItem>
        ))}
      </List>
    )

    const menuList = (
      <>
        {this.state.menuItems.map((menuItem, i) => (
          <div className={classes.rightItem} key={`list-menu-${i}`}>
            <div
              onClick={this._handleOnClick(menuItem.link)}
              className={classes.menuLink}
            >
              <div className={classes.rightLink}>{menuItem.name}</div>
            </div>
          </div>
        ))}
      </>
    )

    return (
      <div className={classes.root}>
        <div
          className={classNames(classes.componentWrapper, classes.container)}
        >
          <div className={classes.left}>
            <Link to={"/"} className={classes.menuLink}>
              <img
                src="/assets/images/logo_header.svg"
                className={classes.headerLogo}
                alt="logo header"
              />
            </Link>
          </div>
          <div className={classes.right}>
            <div className={classes.iconButtonRoot}>
              <IconButton
                aria-haspopup="true"
                onClick={this._toggleDrawer(true)}
                className={classes.button}
              >
                <Menu />
              </IconButton>
            </div>
            <>{menuList}</>
          </div>
          <Drawer
            open={this.state.openDrawer}
            onClose={this._toggleDrawer(false)}
            anchor="top"
          >
            <div
              tabIndex={0}
              role="button"
              onClick={this._toggleDrawer(false)}
              onKeyDown={this._toggleDrawer(false)}
            >
              {menuListDrawer}
            </div>
          </Drawer>
        </div>
      </div>
    )
  }
}

const styles: any = (theme: any) => ({
  root: {
    fontSize: 30,
    paddingTop: 28,
    [theme.breakpoints.down("xs")]: {
      paddingTop: 6,
    },
  },
  componentWrapper: {
    maxWidth: 1920,
    margin: "auto",
  },
  container: {
    display: "flex",
    alignItems: "center",
  },
  iconButtonRoot: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  title: {
    width: 300,
  },
  titleText: {
    color: "#333",
    [theme.breakpoints.down("xs")]: {
      fontSize: 12,
    },
  },
  right: {
    marginLeft: "auto",
    marginRight: 24,
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      marginRight: 16,
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: 8,
    },
  },
  rightItem: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  rightLink: {
    height: 24,
    fontSize: 22,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: 1.2,
    letterSpacing: 0.7,
    textAlign: "center",
    color: "#333",
    textTransform: "uppercase",
    display: "inline-block",
  },
  left: {
    //width: 300,
    marginTop: 21,
    marginLeft: 24,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 16,
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: 8,
    },
  },
  button: {
    textTransform: "none",
    color: "#333",
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 6,
  },
  drawerTitleWrapper: {
    paddingTop: 14,
    paddingBottom: 14,
    paddingLeft: 8,
  },
  menuLink: {
    textDecoration: "none",
    cursor: "pointer",
  },
  contactUs: {
    position: "absolute",
    top: 20,
    color: "#1e365c",
    display: "flex",
    alignItems: "center",
  },
  contactUsText: {
    display: "flex",
    alignItems: "center",
    fontSize: 20,
    fontWeight: "bold",
    fontStyle: "normal",
    fontStretch: "normal",
    color: "#1e365c",
    paddingLeft: 6,
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      fontSize: 15,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 15,
    },
  },
  headerLogo: {
    width: 200,
    [theme.breakpoints.down("xs")]: {
      width: 100,
    },
  },
  image: {
    margin: 0,
    padding: 0,
  },
})

export default compose(pure, withWidth(), withStyles(styles))(Header)
