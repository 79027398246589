import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import { withAssetPrefix } from "gatsby"
import classNames from "classnames"

type Props = {
  classes: any
}

class IndexHero extends Component<Props> {
  componentDidMount() {
    const element = document.getElementById("index-hero-caption")
    setTimeout(() => {
      element.classList.add("fade-in")
    }, 500)
  }

  render() {
    const { classes } = this.props

    return (
      <div
        id="index-hero-caption"
        className={classNames(classes.container, "animation")}
      >
        <div className={classes.caption}>
          リコレクトのオンラインカウンセリング
        </div>
        <img
          src="/assets/images/logo.jpg"
          className={classes.topImage}
          alt="プラスメンタル"
        />
      </div>
    )
  }
}

const styles: any = (theme: any) => ({
  container: {
    //padding: 40,
    display: "flex",
    height: 1080 / 1.5,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      height: "80%",
    },
  },
  topImage: {
    maxWidth: 686,
    width: "100%",
  },
  caption: {
    fontFamily: "HiraKakuPro-W6",
    fontSize: 30,
    paddingBottom: 20,
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
  },
})

export default withStyles(styles)(IndexHero)
